<template>
  <div>

    <!-- banner start -->
    <section class="banner_sec virtual">
      <div class="banner_hed">
        <h1 class="heading_2">Ergo Anytime. Anywhere. Any Device.</h1>
      </div>
    </section>

    <!-- heading start -->
    <section class="bg_grey heading_sec">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="heading_box webinars_heading_banner">
              <h3 class="heading_3">Are your employees working from home? <br />At the office? A bit of both? We’ve got
                you covered.</h3>
            </div>
          </div>
          <div class="col-md-12">
            <div class="learn_more_btn text-center upMar">
              <button v-scroll-to="{el: '#why-choose1',easing: 'linear',offset: -230,}" class="no-border">
                <img src="../../assets/home/images/down_arrow.png" alt="img" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- heading end -->

    <!-- banner end -->

    <!-- right left text start -->
    <section class="right_left_text_sec padding_100" id="why-choose1">
      <div class="container">
        <div class="row">

          <div class="col-md-5 col-xl-5 virtual-eve">
            <div class="right_text_virtual">
              <h5 class="heading_5">Virtual Evaluations</h5>
              <p>
                Wherever your employees find themselves these days, working safely and productively is essential to
                their well being. Our expert staff can help you anywhere in the world through virtual evaluations.
              </p>
            </div>
          </div>
          <div class="col-md-2 col-xl-1 virtual-arrow">
            <div class="web_arrow">
              <img src="../../assets/home/images/webinars/arrow.svg" alt="img" />
            </div>
          </div>
          <div class="col-md-5 col-xl-6 evaluations-include">
            <div class="right_text_virtual virtual_right_box">
              <h5 class="heading_5">Evaluations Include</h5>
              <ul class="m-0 pl_22 bigscreen">
                <li>
                  <p>A full analysis of work setup</p>
                </li>
                <li>
                  <p>A personalized action plan</p>
                </li>
                <li>
                  <p>Behavioral & equipment recommendations</p>
                </li>
                <li>
                  <p>Automated ergo reports & follow-up surveys</p>
                </li>
                <li>
                  <p>Backend data base and metrics</p>
                </li>
                <li>
                  <p>A dedicated client manager</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="webinars_btn text-center mt_60">
              <router-link class="btn btn_primary px_82" :to="{ name: 'learn_more' }">
                Schedule Today!
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- right left text end -->

    <!-- WFH start -->
    <section class="wfh_sec bg_grey hybrid_wfh padding_100">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="wfh_heading text-center">
              <h3 class="heading_3 text_black font_bold" id="getfour">
                Work From Home and Hybrid Webinars
              </h3>
            </div>
          </div>
        </div>

        <div class="row" >
          <div id="accordionExample" class="accordion d-flex">
            <div class="col-md-4 col-xl-3">
              <div class="wfh_box">
                <img src="../../assets/home/images/webinars/w1.png" alt="img" />

                <div class="accordion-item" id="get_one">
                  <h2 id="headingOne" class="accordion-header" @click="scrollfour">
                    <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"
                      aria-controls="collapseOne" class="accordion-button collapsed">
                      Ergo & <br />Productivity
                    </button>
                  </h2>
                  <div id="collapseOne" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                    class="accordion-collapse collapse">
                    <div class="accordion-body">
                      <p>
                        With the dramatic change in our workplaces and work
                        styles, being on top of our ergonomics, healthy work
                        practices and staying focused and productive is more
                        important than ever.
                      </p>
                      <h6>Webinar Topics Include:</h6>
                      <ul class="m-0 pl_20">
                        <li>Optimizing Your Schedule</li>
                        <li>Ergo Anywhere Setup Guidelines.</li>
                        <li>DIY Ergo</li>
                        <li>Productivity Strategies</li>
                        <li>Focus Exercises</li>
                        <li>Dynamic Stretches</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-xl-3">
              <div class="wfh_box">
                <img src="../../assets/home/images/webinars/w2.png" alt="img" />
                <div class="accordion-item" id="get_two">
                  <h2 id="headingTwo" class="accordion-header" @click="scrollone">
                    <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                      aria-controls="collapseTwo" class="accordion-button collapsed">
                      Ask The <br />
                      Ergonomist
                    </button>
                  </h2>
                  <div id="collapseTwo" aria-labelledby="headingTwo" data-bs-parent="#accordionExample"
                    class="accordion-collapse collapse">
                    <div class="accordion-body">
                      <p>
                        “Ask the Ergonomist” is an opportunity to both learn from and ask questions of a professional
                        ergonomist.
                      </p>
                      <h6>
                        Webinar Topics Include:
                      </h6>
                      <ul class="m-0 pl_20">
                        <li>
                          Set Yourself Up for Success
                        </li>
                        <li>Troubleshooting Strategies</li>
                        <li>Ask The Ergonomist Session</li>
                        <li>Learn From Each Other</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-xl-3" >
              <div class="wfh_box">
                <img src="../../assets/home/images/webinars/w3.png" alt="img" />
                <div class="accordion-item" id="get_three">
                  <h2 id="headingThree" class="accordion-header" @click="scrolltwo">
                    <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree"
                      aria-expanded="false" aria-controls="collapseThree" class="accordion-button collapsed">
                      Stress Less <br />Thrive More
                    </button>
                  </h2>
                  <div id="collapseThree" aria-labelledby="headingThree" data-bs-parent="#accordionExample"
                    class="accordion-collapse collapse">
                    <div class="accordion-body">
                      <p>
                        Between WFH, Hybrid Working or Returning to the Office, back-to-back video meetings and many
                        unknowns, it’s fair to say that stress levels are on the rise.
                      </p>
                      <h6>Webinar Topics Include:</h6>
                      <ul class="m-0 pl_20">
                        <li>Spotting Stress Overload</li>
                        <li>Stress Management</li>
                        <li>Energy & Time Management</li>
                        <li>The Art of Self-Care</li>
                        <li>Q & A</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 col-xl-3" >
              <div class="wfh_box">
                <p class="home-s_new">New !</p>
                <img src="../../assets/home/images/webinars/virtual_webinars.png" alt="img" />
                <div class="accordion-item" >
                  <h2 id="headingFour" class="accordion-header" @click="scrollthree">
                    <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false"
                      aria-controls="collapseFour" class="accordion-button collapsed" >
                      Home Stretch:  <span class="home_stretch_web">Stretches to Feel Great 
                        While Working From Home</span>
                    </button>
                  </h2>
                  <div id="collapseFour" aria-labelledby="headingFour" data-bs-parent="#accordionExample"
                    class="accordion-collapse collapse">
                    <div class="accordion-body">
                      <p>Stretches can help combat stresses put on your body and help you develop and maintain healthy postures wherever you work.</p>
                      <p>Join us to learn the best practices, stretches and exercises how to feel your best throughout your day!</p>
                      <h6>Webinar Topics Include:</h6>
                      <ul class="m-0 pl_20">
                        <li>Top 5 Ergo Traps</li>
                        <li>Posture Perfect Exercises</li>
                        <li> Stretching for Success</li>
                        <li>Establishing a Daily Routine</li>
                        <li> Q & A</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- last div -->
          </div>
          <!--<div class="col-md-6 col-xl-3">
                <div class="wfh_box">
                  <router-link class="" :to="{ name: '/' }">
                    <img src="../../assets/home/images/webinars/w1.png" alt="img" />
                    <h6 class="heading_6">Ergo & <br />Productivity</h6>
                  </router-link>
                </div>  
              </div>


              <div class="col-md-6 col-xl-3">
                <div class="wfh_box">
                <router-link class="" :to="{ name: '/' }">
                  <img src="../../assets/home/images/webinars/w2.png" alt="img" />
                  <h6 class="heading_6">Ask The <br />Ergonomist</h6>
                </router-link>    
                </div>
              </div>

              <div class="col-md-6 col-xl-3">
                <div class="wfh_box">
                <router-link class="" :to="{ name: '/' }">
                  <img src="../../assets/home/images/webinars/w3.png" alt="img" />
                  <h6 class="heading_6">Stress Less <br />
                    Thrive More</h6>
                   </router-link>
                </div>
              </div>

              <div class="col-md-6 col-xl-3">
                <div class="wfh_box">
                <router-link class="" :to="{ name: '/' }">
                  <img src="../../assets/home/images/webinars/w4.png" alt="img" />
                  <h6 class="heading_6">Ergo Tips for <br />
                    Parents & Kids</h6>
                    </router-link>
                </div>
                
              </div> -->

          <!-- last div -->
        </div>

      </div>
    </section>
    <!-- WFH end -->

    <!-- return back to office start -->
    <section class="bact_to_office padding_100">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="back_heading text-center">
              <h3 class="heading_3 mb_80 font_bold text_black">Returning Back To the Office? <br />
                Find out how we can help.</h3>
              <router-link class="btn btn_primary" :to="{ name: 'learn_more' }">
                Let’s Chat
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- return back to office end -->

  </div>

</template>

<script>
  // import { mapGetters } from "vuex";
  import store from "@/store/index";
  import $ from "jquery";
  export default {
    name: "virtual_service",
    methods: {
    scrollone(){ 
      setTimeout(function() {
      const mediaQuery = window.matchMedia('(max-width: 767px)')
      if (mediaQuery.matches) {
        $('html,body').animate({ 
        scrollTop: $("#get_one").offset().top},
        'slow');
      }
        }, 500);
      },
       scrolltwo(){ 
        setTimeout(function() {
        const mediaQuery = window.matchMedia('(max-width: 767px)')
        if (mediaQuery.matches) {
        $('html,body').animate({ 
        scrollTop: $("#get_two").offset().top},
        'slow');
        }
          }, 500);
      },
      scrollthree(){  
      setTimeout(function() {
      const mediaQuery = window.matchMedia('(max-width: 767px)')
      if (mediaQuery.matches) { 
      $('html,body').animate({ 
      scrollTop: $("#get_three").offset().top},
      '5000');
      }
       }, 500);
    },
     scrollfour(){  
      const mediaQuery = window.matchMedia('(max-width: 767px)')
      if (mediaQuery.matches) { 
        $('html,body').animate({ 
        scrollTop: $("#getfour").offset().top},
        'slow'); 
      }
      }
    },
    mounted() {
      document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
      store.dispatch("Home/getHomeData").then(() => {});
    },
  }


</script>